/*
 * T_wc_goal
 */
$className: T_wc_goal;

.#{$className} {
  font-family: "Rubik";
  //font-weight: 400;
  -webkit-font-smoothing: antialiased;

  .green-glow {
    color: #d2f95a;
  }

  .own-goal-marker {
    font-size: 27px;
    align-items: center;
    justify-content: center;
    line-height: 70px;
    margin-left: 12px;
    //color: #ff2f2f;
  }

  .wc-content {
    position: absolute;
    width: 1730px;
    height: 860px;
    left: 95px;
    top: 130px;
    z-index: 10;
  }

  .content {
    padding-top: 60px;
    height: 100%;
  }

  .main-header {
    font-size: 75px;
    font-weight: 500;
    display: flex;
    justify-content: flex-start;
    line-height: 1;
    margin-bottom: 0;
    z-index: 100;
    position: relative;
    text-transform: uppercase;
    top: 40px;
    left: 40px;
  }

  .headline {
    font-family: "EdoSZ";
    font-size: 215px;
    position: absolute;
    top: 463px;
    text-align: center;
    left: 0;
    width: 100%;
  }

  .date-container {
    display: flex;
  }

  .container {
    display: flex;
    height: 100%;

    .left {
      flex: 1;

      .comp-match-score-widget {
        position: relative;
        top: 50px;
      }

      .comp-player-triko {
        position: relative;
        top: 50px;
      }

      .team-name {
        font-size: 30px;
        line-height: 1;
        text-align: center;
        text-transform: uppercase;
        font-weight: 400;
        margin-top: 25px;
        white-space: nowrap;
      }
    }

    .right {
      width: 1150px;

      .subheadline-and-logo {
        display: flex;
        justify-content: space-between;
        margin-bottom: 125px - 50px;

        .subheadline {
          font-size: 39px;
          color: #fff;
          z-index: 1;
          text-transform: uppercase;
          font-weight: 400;
          left: 50px;
          position: relative;
        }

        .qatar-logo {
          z-index: 10;
          margin-right: 42px;
          width: 320px;
          filter: drop-shadow(2px 2px 2px black);

          img {
            width: 100%;
          }
        }
      }

      .pitch-container {
        padding-top: 55px;
        overflow: hidden;
        /* margin-top: -12px; */
        position: absolute;
        bottom: 65px;
        justify-content: center;
        display: flex;
        width: 100%;

        .pitch {
          width: 929px;
          background-repeat: no-repeat;
          height: 669px;
          position: relative;

          .circle {
            width: 60px;
            height: 60px;
            line-height: 60px;
            border-radius: 50%;
            background-color: #dd0e26;
            position: absolute;
            text-align: center;
            opacity: 0;
            font-size: 38px;
            font-weight: 400;

            &.goal {
              width: 70px;
              height: 70px;
              line-height: 70px;
              background-color: rgb(222, 244, 119);
            }

            &.shooter {
              //width: 80px;
              //height: 80px;
              //line-height: 80px;
              //font-size: 55px;
            }
          }

          svg {
            width: 100%;
            height: 100%;
          }

          path {
            fill: none;
            stroke-width: 8;
            stroke-linecap: round;
            stroke: rgb(235, 235, 239);
            opacity: 0;

            &.event-16 {
              stroke-width: 8;
              stroke: rgb(222, 244, 119);
            }
          }
        }
      }
    }
  }

  .left-block-side {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    width: 560px;
    height: 100%;
    background-color: #0d4d5c;
  }

  .right-block-side {
    position: absolute;
    right: 0;
    width: 1190px;
    height: 100%;
  }
}
