/*
 * T_wc_lastgames
 */
$className: T_wc_lastgames;

.#{$className} {
  font-family: "Rubik";
  //font-weight: 400;
  -webkit-font-smoothing: antialiased;
  line-height: 1.5715;


  .green-glow {
    color: #d2f95a;
  }

  .left-block-side {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    width: 560px;
    height: 100%;
    background-color: #0d4d5c;
  }

  .right-block-side {
    position: absolute;
    right: 0;
    width: 1190px;
    height: 100%;
  }

  .wc-content {
    position: absolute;
    width: 1730px;
    height: 860px;
    left: 95px;
    top: 130px;
    z-index: 10;
  }

  .qatar-logo {
    position: absolute;
    top: 42px;
    right: 56px;
    z-index: 10;
    margin: auto;
    width: 320px;
    filter: drop-shadow(2px 2px 2px black);

    img {
      width: 100%;
    }
  }

  .main-header {
    top: 100px;
    left: 100px;
    font-size: 160px;
    position: absolute;
    line-height: 0.9;
    text-transform: uppercase;
    font-weight: 700;
  }

  .info-block {
    position: absolute;
    top: 580px;
    left: 100px;
    font-size: 30px;
    font-weight: 400;
  }

  .competition-info {
    text-transform: uppercase;
    line-height: 1.2;
    margin-bottom: 20px;
  }

  .versus-info {
    position: absolute;
    top: 60px;
    left: 60px;
    font-size: 30px;
    font-weight: 400;
    z-index: 5;
    text-transform: uppercase;
    line-height: 1.2;
    padding-left: 16px;
    border-left: 3px solid #006e89;

    .group {
      font-weight: 700;
    }
  }

  .teams-block {
    display: flex;
    position: absolute;
    top: 310px;
    left: 0;
    right: 0;
    /* width: 100%; */

    &.home {
      justify-content: center;
    }

    &.away {
      justify-content: center;
    }

    .home,
    .away {
      //position: absolute;
      width: 400px;
      height: 350px;
      //height: 200px;

      img {
        //width: 100%;
        //height: 100%;
        //object-fit: contain;
      }
    }

    .team-name {
      font-size: 40px;
      line-height: 1;
      text-align: center;
      text-transform: uppercase;
      font-weight: 300;
      white-space: nowrap;
    }
  }

  .score {
    font-size: 70px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    line-height: 1;
    margin-top: 30px;
    text-transform: uppercase;
    padding: 0 35px;

    //.score-home {
    //  width: 150px;
    //  text-align: right;
    //}
    //
    //.score-away {
    //  width: 150px;
    //  text-align: left;
    //}
    //
    //.separator {
    //  margin: 0 10px;
    //}
  }

  .sponsors {
    position: absolute;
    top: 15px;

    &.sponsor-del {
      left: 49px;
      width: 150px;
      top: 70px;
    }

    &.sponsor-sap {
      right: 50px;
      width: 260px;
      top: 70px;
    }
  }

  .headline {
    font-family: "EdoSZ";
    font-size: 215px;
    position: absolute;
    top: 463px;
    text-align: center;
    left: 0;
    width: 100%;
  }

  .date-container {
    display: flex;
  }

  .gameInfo {
    //font-size: 42px;
    font-weight: 600;
    position: relative;
    margin-top: 50px;
    //position: absolute;
    //top: 881px;
    //right: 328px;
    text-align: center;
    line-height: 1;
    letter-spacing: -2px;
    text-transform: uppercase;

    .hashtag {
      //margin-right: 46px;
      color: #e40520;
    }

    .date-container {
      margin-top: 6px;
      //font-size: 50px;
    }

    .separator {
      margin: 0 5px;
    }

    span {
      font-family: "Predator-Sans-SemiLight";
    }
  }

  .competition-logo-container {
    //border-radius: 20px;
    height: 80px;
    width: 90px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin-right: 15px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .team-stats {
    position: relative;
    left: 0;
    top: 160px;
    font-size: 32px;
    width: 1100px;
    margin: auto;

    .stat-label {
      text-transform: uppercase;
      font-weight: 400;
    }

    .stat-row {
      display: flex;
      align-items: center;
      border-top: 3px solid #3b7e89;
      padding: 10px 40px;
      justify-content: space-between;

      .stat-away,
      .stat-home {
        font-size: 35px;
      }

      &:last-child {
        border-bottom: 3px solid #3b7e89;
      }
    }
  }

  .venue-container {
    text-transform: uppercase;
  }

  .group-rows {
    display: flex;
    width: 100%;
    //flex-direction: column;
    flex-wrap: wrap;
    position: relative;

    .row-score {
      width: 125px;
      text-align: center;
      font-size: 44px;
      font-weight: 600;
    }

    .match-label-date {
      font-size: 23px;
      font-weight: 300;
      line-height: 1.1;
      padding-right: 5px;
      width: 106px;
      white-space: nowrap;
    }

    .match-label {
      margin-bottom: 10px;
      font-size: 24px;
      color: #d2f95a;
      display: flex;


      .match-label-competition {
        //font-weight: 300;
      }

      & > div {
        margin-right: 20px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .match-row {
      display: flex;
      align-items: center;
      width: 100%;

      img.country-flag {
        padding: 5px;
      }
    }

    .group-row {
      flex-direction: column;
      //display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      //margin-bottom: 65px;
      line-height: 1;

      display: flex;
      align-items: center;
      border-top: 4px solid #3b7e89;
      padding: 12px 0;

      &:first-child {
        border-top: 4px solid transparent;
      }

      &:last-child {
        border-bottom: 4px solid #3b7e89;
      }

      .team-home, .team-away {
        width: 80px;
        height: 62px;
        //position: absolute;
        //top: 470px;
        filter: drop-shadow(2px 2px 2px black);

        img {
          width: 100%;
          height: 100%;
        }
      }

      .team-home {
        margin-left: 15px;
      }

      .team-away {
        margin-right: 15px;
      }

      .team-name {
        flex: 1;
        font-size: 28px;
        font-weight: 400;

        &.home {
          text-align: right;
        }

        &.away {
          text-align: left;
        }
      }
    }
  }

  .result-indicator {
    width: 32px;
    height: 32px;
    text-align: center;
    font-size: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;

    &.result-win {
      background-color: #90b034;
    }

    &.result-draw {
      background-color: #efa900;
    }

    &.result-lost {
      background-color: #ff0000;
    }
  }

  .game-type-last {
    .timestamp {
      display: none;
    }
  }

  .score-type {
    color: #d2f95a;

    font-size: 24px;
    font-weight: 400;
    text-align: center;
  }
}
