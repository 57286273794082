@import './_variables.scss';
@import '../frontend/main';

@import './clients/app-adler';
@import './clients/app-opta';

$scale: 0.35;

.main-sidenav {
  min-width: 200px;

  .logo {
    font-weight: 800;
    color: #a2d7fd;
    letter-spacing: 1.6px;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1;
    font-size: 16px;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 5px;


    span {
      display: inline-block;
      transition: transform 0.6s ease;
      transform-style: preserve-3d;
    }
  }

  .logo span:hover {
    transform: scale(1.1);
    cursor: pointer;
  }
}

.overview-main-buttons {
  display: flex;

  > div {
    margin-right: 10px;
  }

  > div:last-child {
    margin-right: 0;
  }
}

.zenga-button-1 {
  background-color: white;
  height: 40px;
  width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $_1stColor;
  font-weight: 500;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    color: #40a9ff;
  }

  .anticon {
    margin-right: 5px;
    font-size: 18px;
  }
}

.zenga-global-actions {
  background-color: white;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $_1stColor;
  font-weight: 500;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    color: #40a9ff;
  }

  .anticon {
    margin-right: 0;
    font-size: 18px;
  }
}

.main-button-worldcup {
  background-color: #d2f95a;;
  height: 40px;
  min-width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  font-weight: 500;
  border-radius: 5px;
  cursor: pointer;
  padding: 0 8px;
  line-height: 1;

  &:hover {
    color: #e20000;
  }

  .anticon {
    margin-right: 5px;
    font-size: 18px;
  }
}


.comp-template-preview {
  &.f-1920x1080 {
    .template-container {
      transform: scale($scale); //calculate scale for current preview mode
      transform-origin: left top;
    }
  }

  &.f-1200x1500 {
    .template-container {
      transform: scale($scale); //calculate scale for current preview mode
      transform-origin: left top;
      left: 130px;
    }
  }

  &.f-1080x1920 {
    .template-container {
      transform: scale($scale); //calculate scale for current preview mode
      transform-origin: left top;
      left: 145px;
    }
  }
}

.comp-timeline-preview {
  position: relative;
  $thisWidth: 1920px;
  $thisHeight: 1080px;
  $thisScale: 0.35;

  &.f-1920x1080 {
    .timeline-container {
      transform: scale($thisScale); //calculate scale for current preview mode
      transform-origin: left top;
      height: $thisHeight;
      width: $thisWidth;
      overflow: hidden;
    }
  }

  .timeline-progress-bar {
    top: ($thisHeight + 15px)*$thisScale;
  }
}

.edit-container {

}

.fullscreen-enabled {
  .comp-template-preview {
    .template-container {
      transform: scale(1);
      transform-origin: center center;
    }
  }

  .comp-timeline-preview {
    .timeline-container {
      transform: scale(1);
      transform-origin: center center;
    }
  }
}


.ant-layout-main-element {
  height: 100%;
  flex-wrap: wrap;
}

.root-container {
  height: 100%;
}

.main-footer {
  padding: 10px 5px;
}


#components-layout-demo-custom-trigger .trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.ant-layout-header.site-layout-background {
  background: $_2ndColor;
  height: 100px;
}

li.ant-menu-item {
  display: flex;
  align-items: center;
}


.header-logo {
  display: block;
  width: 100px;
  height: 100px;
  position: absolute;
  object-fit: contain;
  top: 0;
  left: 80px;
  z-index: 10;
  transition: transform 0.6s ease;
  transform-style: preserve-3d;

  &:hover {
    transform: scale(1.1);
    cursor: pointer;
  }

}

.header-icons {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;

  .anticon {
    margin-left: 10px;
  }

  .form-input-media {
    .anticon {
      font-size: 20px;
      position: relative;
      top: 1px;
    }

    display: inline-block;
    line-height: 1;
  }


}

.main-header-flex {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: 20px;
  //line-height: 1;

  span {
    line-height: 1;
  }

  .lang-switcher {
    position: absolute;
    //margin-left: 25px;
    top: -3px;
    right: 45px;
    width: 150px;
    line-height: 1;

    span {
      //cursor: pointer;
      color: red;

      &.active {
        //text-decoration: underline;
      }

      &:hover {
        //text-decoration: underline;
      }
    }
  }
}

.main-content.site-layout-background {
  margin: 0;
  position: relative;
  //background-color: #F1F2F6;
}

.main-content {
  & > .container {
    position: relative;
    z-index: 20;
  }

  overflow: auto;
}

.backend-main-background-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .bg-layer-mask {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    background-color: rgba(0, 0, 0, 0.25);
  }

  .backend-main-background {
    background-size: cover;
    background-repeat: no-repeat;
    width: inherit;
    height: inherit;
  }
}

.ant-modal {
  line-height: inherit;

  .ant-modal-body {
    line-height: inherit;
  }
}

.page-container {
  position: relative;
  z-index: 5;
  margin-top: 15px;
  margin-bottom: 15px;
}

.link {
  cursor: pointer;
}


.main-login-page {
  height: 100%;

  .ant-form-item {

    input {
      border-radius: 5px;
    }

  }

  .submit-button {
    text-align: right;
    width: 150px;
    margin: auto;

    button {
      width: 100%;
    }
  }

  .main-login-header {
    background-color: white;
    padding: 10px 20px;
    border-radius: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    width: 220px;
    margin: 0 auto 30px;

    img {
      display: block;
      margin: auto;
      border-bottom: 1px solid black;
      padding-bottom: 5px;
      max-width: 100%;
    }

    .product-name {
      padding-top: 5px;
      font-weight: 300;
      letter-spacing: 2px;
    }
  }

  .ant-layout-content {
    //align-items: center;
    margin-top: 200px;
    display: flex;
    justify-content: center;
  }

  .card-container {
    width: 400px;
  }

  .login-footer {
    text-align: center;
    font-weight: 400;
  }
}

.current-user {
  background-color: white;
  margin-left: 10px;
  width: 200px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 20px;
  border-radius: 4px;

  .ant-dropdown-trigger {
    height: 100%;
    line-height: 1.65;
  }

  .ant-dropdown-link > .anticon.anticon-down {
    margin-left: 5px;
    font-size: 14px;
    vertical-align: middle;
  }

  a {
    color: black;
  }
}


.lang-switch {
  .active {

    color: #1491ff;
  }
}

.loader-container {

  &.fullscreen, &.component {
    background-color: $_2ndColor;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;


    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background-color: rgba(255, 255, 255, 0.8);
      margin-bottom: 50px;
      font-size: 56px;
      //color: #31ABFE;
      color: #5aaff2;
      width: 200px;
      height: 200px;

      border: 3px solid black;
      border-radius: 10px;

    }


    img {
      margin-bottom: 15px;
      width: 120px;
    }

  }

  &.component {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.5);
  }

  &.inline {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    //background-color: rgba(255, 255, 255, 0.5);

    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background-color: rgba(255, 255, 255, 0.8);
      font-size: 56px;
      //color: #31ABFE;
      color: #5aaff2;
      width: 80px;
      height: 80px;
      margin: 10px 15px;
      border: 3px solid black;
      border-radius: 10px;
    }

    img {
      display: none;
      margin-bottom: 15px;
      width: 120px;
    }
  }

}

.jumbotron.main-page-header {
  display: flex;
  margin-bottom: 15px;
  align-items: center;

  h2 {
    color: white;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 24px;
    margin: 0;
    padding: 0 5px;
    border: 2px solid white;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.15);
  }

  & > div {
    margin-left: auto;
  }
}


@keyframes animate-stripes {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 30px 0;
  }
}


.slack-chat-container {
  position: relative;
  z-index: 20;
}

.site-layout {
  height: 100%;
}

.ant-layout-sider {
  position: relative;
  z-index: 20;
}

.ant-layout-header {
  position: relative;
  z-index: 50;
}

.comp-LiveWidgetDrawer {
  .ant-drawer-body {
    overflow: hidden;
  }
}

.edit-container {
  .form-side {
    overflow: auto;

    .media-preview-block {
      .preview-label {
        display: none;
      }
    }
  }
}

.comp-LiveWidgetDrawer {
  .widget-container {
    height: 100%;
  }
}

.setup-form-timeline-extras {
  .form-input-media {
    .ant-form-item {
      margin-bottom: 0;
    }

    .ant-col {
      max-width: 100%;
    }
  }
}

