/*
 * T_wc_teamcompare
 */
$className: T_wc_teamcompare;

.#{$className} {
  font-family: "Rubik";
  //font-weight: 400;
  -webkit-font-smoothing: antialiased;

  .green-glow {
    color: #d2f95a;
  }

  .wc-content {
    position: absolute;
    width: 1730px;
    height: 860px;
    left: 95px;
    top: 130px;
    z-index: 10;
  }

  .qatar-logo {
    position: absolute;
    top: 65px;
    right: 56px;
    z-index: 10;
    margin: auto;
    width: 320px;
    filter: drop-shadow(2px 2px 2px black);

    img {
      width: 100%;
    }
  }

  .info-block {
    position: absolute;
    top: 580px;
    left: 100px;
    font-size: 30px;
    font-weight: 400;
  }

  .competition-info {
    text-transform: uppercase;
    line-height: 1.2;
    margin-bottom: 20px;
  }

  .versus-info {
    position: absolute;
    top: 60px;
    left: 60px;
    font-size: 30px;
    font-weight: 400;
    z-index: 5;
    text-transform: uppercase;
    line-height: 1.2;
    padding-left: 16px;
    border-left: 3px solid #006e89;

    .group {
      font-weight: 700;
    }

  }

  .teams-block {
    display: flex;
    position: absolute;
    top: 320px;
    left: 0;
    right: 0;
    /* width: 100%; */
    justify-content: space-between;

    .home, .away {
      //position: absolute;
      width: 365px;
      height: 280px;
      //height: 200px;

      img {
        //width: 100%;
        //height: 100%;
        //object-fit: contain;
      }
    }

    .team-name {
      font-size: 30px;
      line-height: 1;
      text-align: center;
      text-transform: uppercase;
      font-weight: 400;
      margin-top: 25px;
      white-space: nowrap;
    }
  }

  .main-header {
    font-size: 70px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    line-height: 1;
    margin-bottom: 50px;
    z-index: 100;
    position: relative;
    top: 50px;
    text-transform: uppercase;
  }

  .sponsors {
    position: absolute;
    top: 15px;

    &.sponsor-del {
      left: 49px;
      width: 150px;
      top: 70px;
    }

    &.sponsor-sap {
      right: 50px;
      width: 260px;
      top: 70px;
    }
  }

  .headline {
    font-family: "EdoSZ";
    font-size: 215px;
    position: absolute;
    top: 463px;
    text-align: center;
    left: 0;
    width: 100%;
  }

  .date-container {
    display: flex;
  }

  .gameInfo {
    //font-size: 42px;
    font-weight: 600;
    position: relative;
    margin-top: 50px;
    //position: absolute;
    //top: 881px;
    //right: 328px;
    text-align: center;
    line-height: 1;
    letter-spacing: -2px;
    text-transform: uppercase;


    .hashtag {
      //margin-right: 46px;
      color: #e40520;
    }

    .date-container {
      margin-top: 6px;
      //font-size: 50px;
    }

    .separator {
      margin: 0 5px;
    }


    span {
      font-family: "Predator-Sans-SemiLight";
    }


  }

  .team-stats {
    position: relative;
    left: 0;
    top: 50px;
    font-size: 34px;
    width: 820px;
    margin: auto;

    .stat-label {
      text-transform: uppercase;
      font-weight: 500;
      color: #d2f95a;
    }

    .stat-row {
      height: 110px;
      display: flex;
      align-items: center;
      border-top: 3px solid #015b77;
      padding: 10px 0;
      justify-content: space-between;

      .stat-away, .stat-home {
        font-size: 45px;
      }

      &:last-child {
        border-bottom: 3px solid #015b77;
      }
    }
  }


  .venue-container {
    text-transform: uppercase;
  }
}
